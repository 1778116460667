import React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import HeroImage from "../components/hero-image/hero-image"

const NotFoundPage = () => (
  <Layout>
    <Helmet
        link={[
            {
              rel: "stylesheet",
              href: "https://fonts.googleapis.com/css?family=Teko",
            },
            {
              rel: "stylesheet",
              href: "https://fonts.googleapis.com/css?family=Oswald:wght@300;400;700&display=swap",
            }
        ]} title="404: Not found" />
    <HeroImage
      Title={'Sorry! Page Not Found!'}
    ></HeroImage>
  </Layout>
)

export default NotFoundPage
